import React, { useEffect, useLayoutEffect, useState } from "react";
import { Routes, Route, useLocation } from "react-router-dom";
import { ProductContextProvider } from "../pages/pre-built/products/ProductContext";
import { UserContextProvider } from "../pages/pre-built/user-manage/UserContext";
import { ProtectedRoute } from "./ProtectedRoute";

import Invest from "../pages/Invest";

import Faq from "../pages/others/Faq";
import Regularv1 from "../pages/others/Regular-1";
import Regularv2 from "../pages/others/Regular-2";
import Terms from "../pages/others/Terms";
import UserListRegular from "../pages/pre-built/user-manage/UserListRegular";
import UserContactCard from "../pages/pre-built/user-manage/UserContactCard";
import UserDetails from "../pages/pre-built/user-manage/UserDetailsRegular";
import UserListCompact from "../pages/pre-built/user-manage/UserListCompact";
import UserProfileRegular from "../pages/pre-built/user-manage/UserProfileRegular";
import UserProfileSetting from "../pages/pre-built/user-manage/UserProfileSetting";
import UserProfileNotification from "../pages/pre-built/user-manage/UserProfileNotification";
import UserProfileActivity from "../pages/pre-built/user-manage/UserProfileActivity";
import KycListRegular from "../pages/pre-built/kyc-list-regular/KycListRegular";
import KycDetailsRegular from "../pages/pre-built/kyc-list-regular/kycDetailsRegular";
import TransListBasic from "../pages/pre-built/trans-list/TransListBasic";
import TransListCrypto from "../pages/pre-built/trans-list/TransListCrypto";
import ProductCard from "../pages/pre-built/products/ProductCard";
import ProductList from "../pages/pre-built/products/ProductList";
import ProductDetails from "../pages/pre-built/products/ProductDetails";

import InvoicePrint from "../pages/pre-built/invoice/InvoicePrint";

import Error404Classic from "../pages/error/404-classic";
import Error404Modern from "../pages/error/404-modern";
import Error504Modern from "../pages/error/504-modern";
import Error504Classic from "../pages/error/504-classic";

import Login from "../pages/auth/Login";
import Register from "../pages/auth/Register";
import ForgotPassword from "../pages/auth/ForgotPassword";
import Success from "../pages/auth/Success";

import Layout from "../layout/Index";
import LayoutNoSidebar from "../layout/Index-nosidebar";

//Procurex Pages

import AddNewPurchaseRequest from "../pages/purchase-requests/AddNewPurchaseRequest";
import ViewPurchaseRequests from "../pages/purchase-requests/ViewPurchaseRequests";
import PurchaseRequestAprrovals from "../pages/purchase-requests/PurchaseRequestApprovals";
import PurchaseRequestAnalytics from "../pages/purchase-requests/PurchaseRequestAnalytics";
import TrackPurchaseRequests from "../pages/purchase-requests/TrackPurchaseRequests";

import AddNewBudget from "../pages/budget/AddNewBudget";
import ViewBudget from "../pages/budget/ViewBudget";
import TrackBudget from "../pages/budget/TraclBudget";
import BudgetApprovals from "../pages/budget/BudgetApprovals";
import BudgetAnalytics from "../pages/budget/BudgetAnalytics";

import RFQCreation from "../pages/quotations/RFQCreations";
import RFQAnalytics from "../pages/quotations/RFQAnalytics";
import RFQApprovals from "../pages/quotations/RFQApprovals";
import RFQComparison from "../pages/quotations/RFQComparison";
import RFQConversion from "../pages/quotations/RFQConversion";
import RFQSupplierResponses from "../pages/quotations/RFQSupplierResponses";
import RFQView from "../pages/quotations/RFQView";
import RFQTracking from "../pages/quotations/RFQTracking";

import FACreations from "../pages/forward-auction/FACreations";
import FAApprovals from "../pages/forward-auction/FAApprovals";
import FAComparison from "../pages/forward-auction/FAComparison";
import FASupplierResponses from "../pages/forward-auction/FASupplierResponses";
import FAView from "../pages/forward-auction/FAView";
import FAHistory from "../pages/forward-auction/FAHistory";
import FAAnalytics from "../pages/forward-auction/FAAnalytics";

import RACreations from "../pages/reverse-auction/RACreation";
import RAApprovals from "../pages/reverse-auction/RAApprovals";
import RASupplierResponses from "../pages/reverse-auction/RASupplierResponses";
import RAComparison from "../pages/reverse-auction/RAComparison";
import RAConversion from "../pages/reverse-auction/RAConversion";
import RAView from "../pages/reverse-auction/RAView";
import RAHistory from "../pages/reverse-auction/RAHistory";
import RAAnalytics from "../pages/reverse-auction/RAAnalytics";

import OrgSetup from "../pages/masters/orgmaster/OrgSetup";
import CreateOrganisation from "../pages/masters/orgmaster/CreateOrganisation";
import SitesSetup from "../pages/masters/sites/SitesSetup";
import AddSites from "../pages/masters/sites/CreateSites";
import SitesViewDetails from "../pages/masters/sites/SitesViewDetails";
import Departments from "../pages/masters/departments/Departments";
import Roles from "../pages/masters/roles/Roles";
import ReportingHeirarchy from "../pages/masters/roles/ReportingHeirarchy";
import SupplierCategory from "../pages/masters/supplier/SupplierCategory";
import Suppliers from "../pages/masters/supplier/Suppliers";
import Contractors from "../pages/masters/contractors/Contractors";
import ViewSuppliersDetails from "../pages/masters/supplier/ViewSuppliersDetails";
import AddContractors from "../pages/masters/contractors/AddContractors";
import ViewContractorDetails from "../pages/masters/contractors/ViewContractorDetails";
import ApprovalHeirarchy from "../pages/masters/contractors/ApprovalHeirarchy";
import MaterialCategory from "../pages/masters/materials/MaterialCategory";
import Materials from "../pages/masters/materials/Material";

import CostCenter from "../pages/masters/cost-center/CostCenter";
import GeneralLedger from "../pages/masters/general-ledger/GeneralLedger";
import Consultants from "../pages/masters/consultants/Consultants";
import Users from "../pages/masters/Users/Users";
import CreateUsers from "../pages/masters/Users/CreateUsers";
import AddSupplier from "../pages/masters/supplier/AddSupplier";
import AddConsultants from "../pages/masters/consultants/AddConsultants";
import AddMaterial from "../pages/masters/materials/AddMaterial";
import ViewMaterialDetails from "../pages/masters/materials/ViewMaterialDetails";
import ViewConsultantDetails from "../pages/masters/consultants/ViewConsultantDetails";
import CreateRole from "../pages/masters/roles/CreateRole";
import UserViewDetails from "../pages/masters/Users/ViewUser";
import RoleViewDetails from "../pages/masters/roles/ViewRole";
import ViewOrgPage from "../pages/masters/orgmaster/ViewOrg";
import SetPassword from "../pages/auth/SetPassword";
import SuccessMain from "../pages/auth/SuccessMain";
import EmailError from "../pages/auth/EmailError";
import ResetPassword from "../pages/auth/ResetPassword";
import OrgLegalInformation from "../pages/masters/orgmaster/OrgLegalInformation";
import OrgContactInformation from "../pages/masters/orgmaster/orgContactInformation";
import OrgBankInformation from "../pages/masters/orgmaster/OrgBankInformation";

// import PurchaseOrderMain from "../pages/purchase-orders/PurchaseOrderMain";
import DeliveryAddress from "../pages/masters/delivery-address/DeliveryAddress";
import PurchaseOrderAprrovals from "../pages/purchase-orders/PurchaseOrderApprovals";
import ViewPurchaseOrdersList from "../pages/purchase-orders/ViewPurchaseOrder";
import TrackPurchaseOrder from "../pages/purchase-orders/TrackPurchaseOrder";
import PurchaseOrderAnalytics from "../pages/purchase-orders/PurchaseOrderAnalytics";
import AddInvoice from "../pages/invoice/AddInvoice";
import InvoiceApproval from "../pages/invoice/InvoiceApproval";
import TrackInvoice from "../pages/invoice/TrackInvoice";
import InvoiceAnalytics from "../pages/invoice/InvoiceAnalytics";
import ViewOrderDetails from "../pages/purchase-orders/ViewOrderDetails";
import PoInvoiceDetails from "../pages/invoice/ViewOrderDetails";
import ViewMaterialInvoices from "../pages/invoice/ViewMaterialInvoices";
import ViewInvoiceDetails from "../pages/invoice/ViewInvoiceDetails";
import ViewInvoiceList from "../pages/invoice/ViewAllInvoices";
import RFQViewDetails from "../pages/quotations/ViewRFQDetails";
import QuotedList from "../pages/quotations/QuotedList";
import EditQuotedView from "../pages/quotations/EditQuotedView";
import InvoiceToDispatchCreation from "../pages/dispatch/invoiceToDispatchCreation";
import ViewDispatchInvoiceList from "../pages/dispatch/dispatchableInvoices";
import ViewAllDispatch from "../pages/dispatch/ViewAllDispatch";
import ShowDispatchDetailsWrapper from "../pages/dispatch/showDispatchDetailsWrapper";
import ViewMaterialDispatch from "../pages/dispatch/ViewMaterialDispatch";
import ViewPayments from "../pages/payments/viewPayments";
import RunPayments from "../pages/payments/runPayments";
import Main from "../pages/credit-debit-note/Main";
import ViewAllCN from "../pages/credit-debit-note/ViewAllCN";
import ViewPendingApprovalCN from "../pages/credit-debit-note/ViewPendingApprovalCN";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { fetchCurrentUser } from "../store/slices/misc/currentUser";
import { updateAccessibility } from "../store/slices/misc/accessibility";
import { updateMenuData } from "../store/slices/misc/menuData";
import { updateAllowedRoutes } from "../store/slices/misc/allowedRoutes";
import { Flex, Spin } from "antd";
import SequenceList from "../pages/masters/sequence/SequenceList";
import { fetchSequenceAccess } from "../store/slices/misc/sequenceAccess";
import Cookies from "js-cookie";
const globalRoutes = [
  "/",
  "/auth-reset",
  "/auth-success",
  "/auth-register",
  "/setPassword",
  "/success",
  "/errorEmail",
];
const routesMap = {
  dashboard: <Invest />,
  organisation: <ViewOrgPage />,
  orgLegalInformation: <OrgLegalInformation />,
  orgContactInformation: <OrgContactInformation />,
  orgBankInformation: <OrgBankInformation />,
  sequence: <SequenceList />,
  sites: <SitesSetup />,
  createSites: <AddSites />,
  "delivery-address": <DeliveryAddress />,
  "sitesViewDetails/:siteId": <SitesViewDetails />,
  departments: <Departments />,
  roles: <Roles />,
  "ViewRoleDetails/:roleID": <RoleViewDetails />,
  "reporting-heirarchy": <ReportingHeirarchy />,
  "supplier-category": <SupplierCategory />,
  suppliers: <Suppliers />,
  addSuppliers: <AddSupplier />,
  "vewSuppliersDetails/:siteId": <ViewSuppliersDetails />,
  "general-contractors": <Contractors />,
  "viewContractorDetails/:siteId": <ViewContractorDetails />,
  addContractors: <AddContractors />,
  addConsultants: <AddConsultants />,
  "viewConsultantDetails/:consultantId": <ViewConsultantDetails />,
  addMaterials: <AddMaterial />,
  "vewMaterialDetails/:materialId": <ViewMaterialDetails />,
  consultants: <Consultants />,
  users: <Users />,
  createUsers: <CreateUsers />,
  "userProfile/:userId": <UserViewDetails />,
  createRole: <CreateRole />,
  "approval-heirarchy": <ApprovalHeirarchy />,
  "materail-category": <MaterialCategory />,
  materails: <Materials />,
  "cost-center": <CostCenter />,
  "general-ledger": <GeneralLedger />,
  "add-new-purchase-request": <AddNewPurchaseRequest />,
  "approvals-purchase-request": <PurchaseRequestAprrovals />,
  "view-purchase-requests": <ViewPurchaseRequests />,
  "track-purchase-requests": <TrackPurchaseRequests />,
  "analytics-purchase-requests": <PurchaseRequestAnalytics />,
  "add-budget": <AddNewBudget />,
  "budget-approvals": <BudgetApprovals />,
  "view-budget": <ViewBudget />,
  "track-budget": <TrackBudget />,
  "analytics-budget": <BudgetAnalytics />,
  "new-rfq": <RFQCreation />,
  "rfq-approvals": <RFQApprovals />,
  "view-rfqs": <RFQView />,
  "view-rfqs-details": <RFQViewDetails />,
  "track-rfqs": <RFQTracking />,
  "analytics-rfq": <RFQAnalytics />,
  "rfq-comparison": <RFQComparison />,
  "rfq-conversion": <RFQConversion />,
  quoted_view: <QuotedList />,
  "view-quoted-details/:qid": <EditQuotedView />,
  "rfq-supplier-responses": <RFQSupplierResponses />,
  "approvals-purchase-order": <PurchaseOrderAprrovals />,
  "view-purchase-order": <ViewPurchaseOrdersList />,
  "view-purchase-order/:orderNumber": <ViewOrderDetails />,
  "track-purchase-order": <TrackPurchaseOrder />,
  "analytics-purchase-order": <PurchaseOrderAnalytics />,
  "add-purchase-invoice": <AddInvoice />,
  "add-purchase-invoice/:orderNumber": <PoInvoiceDetails />,
  "view-invoice-list": <ViewInvoiceList />,
  "view-invoice/:id": <ViewInvoiceDetails />,
  "view-material-invoices/:orderNumber/:lineNo": <ViewMaterialInvoices />,
  "approvals-purchase-invoice": <InvoiceApproval />,
  "track-purchase-invoice": <TrackInvoice />,
  "analytics-purchase-invoice": <InvoiceAnalytics />,
  "view-dispatch-invoice-list": <ViewDispatchInvoiceList />,
  "post-dispatch-invoice/:invoiceNumber": <InvoiceToDispatchCreation />,
  "view-material-dispatch/:invoiceNumber/:invoiceLineNo": (
    <ViewMaterialDispatch />
  ),
  "view-dispatch-list": <ViewAllDispatch />,
  "view-dispatch-details/:id": <ShowDispatchDetailsWrapper />,
  "credit-debit-note-new": <Main />,
  "all-credit-debit-notes": <ViewAllCN />,
  "pending-approval-credit-debit-notes": <ViewPendingApprovalCN />,
  "view-payments": <ViewPayments />,
  "pending-payment-approvals": <RunPayments />,
  "kyc-list-regular": <KycListRegular />,
  "kyc-details-regular/:kycId": <KycDetailsRegular />,
  "transaction-basic": <TransListBasic />,
  "transaction-crypto": <TransListCrypto />,
  "pages/terms-policy": <Terms />,
  "pages/faq": <Faq />,
  // "pages/regular-v1": <Regularv1 />,
  // "pages/regular-v2": <Regularv2 />,
};

const menu = [
  {
    text: "Dashboard",
    link: "dashboard",
  },
  {
    text: "Masters",
    subMenu: [
      {
        text: "Organisation",
        link: "organisation",
      },
      {
        text: "Series",
        link: "sequence",
      },
      {
        text: "Manufacturing Locations",
        link: "sites",
      },
      {
        text: "Departments",
        link: "departments",
      },
      {
        text: "Roles",
        active: false,
        subMenu: [
          {
            text: "Roles",
            link: "roles",
          },
          {
            text: "Role Heirarchy",
            link: "reporting-heirarchy",
          },
        ],
      },
      {
        text: "Material Category",
        link: "supplier-category",
      },
      {
        text: "Materials",
        link: "materails",
      },
      {
        text: "Users",
        link: "users",
      },

      {
        text: "Approval Heirarchy",
        link: "approval-heirarchy",
      },

      {
        text: "Cost Center",
        link: "cost-center",
      },
      {
        text: "General Ledger",
        link: "general-ledger",
      },
    ],
  },
  {
    text: "Sales",
    subMenu: [
      // {
      //   text: "Budget",
      //   link: "add-budget",
      //   alt: ["view-budget"],
      // },
      // {
      //   text: "Purchase Request",
      //   link: "add-new-purchase-request",
      //   alt: ["view-purchase-request"],
      // },
      {
        text: "Quotations",
        link: "view-rfqs",
      },
      // {
      //   text: "Forward Auction",
      //   link: "forward-auction-new",
      // },
      // {
      //   text: "Reverse Auction",
      //   link: "reverse-auction-new",
      // },
      {
        text: "Purchase Orders",
        link: "view-purchase-order",
      },
      {
        text: "Invoices",
        link: "view-invoice-list",
      },
      {
        text: "Dispatch",
        link: "view-dispatch-list",
      },
      {
        text: "Credit/Debit Note",
        link: "all-credit-debit-notes",
      },
      {
        text: "Payments Receipts",
        link: "view-payments",
      },
    ],
  },
  // {
  //   text: "Market Place",
  //   subMenu: [
  //     {
  //       text: "Quotations",
  //       link: "analytics-rfq",
  //     },
  //     {
  //       text: "Forward Auction",
  //       link: "forward-auction-new",
  //     },
  //     {
  //       text: "Reverse Auction",
  //       link: "reverse-auction-new",
  //     },
  //     {
  //       text: "Registered Suppliers",
  //       link: "reverse-auction-new",
  //     },
  //   ],
  // },
];

const Pages = () => {
  const { data: currentUser, isLoading } = useSelector(
    (state) => state.currentUser
  );
  const [accessibility, setAccessibility] = useState([]);
  const [restrictedRoutes, setRestrictedRoutes] = useState([]);
  const dispatch = useDispatch();
  const location = useLocation();
  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  useEffect(() => {
    const isAuthenticated = Cookies.get("access-token");
    if (
      !currentUser &&
      isAuthenticated &&
      !globalRoutes?.includes(window.location.pathname)
    ) {
      dispatch(fetchCurrentUser());
    }
  }, [dispatch]);

  useEffect(() => {
    if (currentUser?.userRoutePermissions) {
      try {
        const { userRoutePermissions } = currentUser;
        const decodedString = atob(userRoutePermissions);
        const decodedData = JSON.parse(decodedString);
        console.log("decodedData", decodedData);
        dispatch(updateAccessibility(decodedData));
        const _allowedRoutes = [];
        const _restrictedRoutes = [];
        Object.keys(decodedData).forEach((mod) => {
          const module = decodedData[mod];
          if (module?.access) {
            module?.routes?.forEach((route) => {
              if (route?.permission) {
                _allowedRoutes.push(route?.path);
              } else {
                _restrictedRoutes.push(route?.path);
              }
            });
          } else {
            module?.routes?.forEach((route) => {
              _restrictedRoutes.push(route?.path);
            });
          }
        });
        setAccessibility(_allowedRoutes);
        setRestrictedRoutes(_restrictedRoutes);
        const _menu = [];
        menu?.forEach((item) => {
          if (item?.subMenu?.length > 0) {
            const subMenu = [];
            item?.subMenu?.forEach((subItem) => {
              if (subItem?.subMenu?.length > 0) {
                const _subMenuChildren = [];
                subItem?.subMenu?.forEach((subSubItem) => {
                  if (_allowedRoutes.includes(subSubItem?.link)) {
                    _subMenuChildren.push({
                      text: subSubItem?.text,
                      link: subSubItem?.link,
                    });
                  }
                });
                if (_subMenuChildren.length > 0) {
                  subMenu.push({
                    text: subItem?.text,
                    active: false,
                    subMenu: _subMenuChildren,
                  });
                }
                return;
              }
              if (_allowedRoutes.includes(subItem?.link)) {
                subMenu.push({
                  text: subItem?.text,
                  link: subItem?.link,
                });
              } else {
                if (subItem?.alt?.length > 0) {
                  const altLink = subItem?.alt?.find((link) =>
                    _allowedRoutes.includes(link)
                  );
                  if (altLink) {
                    subMenu.push({
                      text: subItem?.text,
                      link: altLink,
                    });
                  }
                }
              }
            });
            if (subMenu.length > 0) {
              _menu.push({
                text: item?.text,
                subMenu: subMenu,
              });
            }
          } else {
            if (_allowedRoutes.includes(item?.link)) {
              _menu.push({
                text: item?.text,
                link: item?.link,
              });
            }
          }
        });
        dispatch(updateMenuData(_menu));
        dispatch(updateAllowedRoutes(_allowedRoutes));
      } catch (error) {
        console.log("error", error);
      }
    }
  }, [currentUser]);

    useEffect(() => {
    const isAuthenticated = Cookies.get("access-token");
    if (!isAuthenticated && !globalRoutes?.includes(window.location.pathname)) {
      window.location.href = "/";
    }
  }, []);

  return (
    <Routes>
      <Route path={`${process.env.PUBLIC_URL}`} element={<Layout />}>
        {accessibility?.map((path) => (
          <Route path={path} element={routesMap[path]} />
        ))}
        {restrictedRoutes?.map((path) => (
          <Route path={path} element={<div> Unauthorized Access</div>} />
        ))}
        {/* <Route
          path="post-dispatch-invoice/:invoiceNumber"
          element={<InvoiceToDispatchCreation />}
        /> */}
        {/* <Route path={"add-purchase-invoice"} element={<AddInvoice />} />
        <Route
          path={"add-purchase-invoice/:orderNumber"}
          element={<PoInvoiceDetails />}
        />
        <Route
          path="view-dispatch-invoice-list"
          element={<ViewDispatchInvoiceList />}
        />
        <Route path="quoted_view" element={<QuotedList />} />
        <Route path="add-budget" element={<AddNewBudget />}></Route> */}
         <Route
          path="user-profile-regular"
          element={<UserProfileRegular />}
        ></Route>
        <Route
          path="user-profile-notification"
          element={<UserProfileNotification />}
        ></Route>
        <Route
          path="user-profile-activity"
          element={<UserProfileActivity />}
        ></Route>
        <Route
          path="user-profile-setting"
          element={<UserProfileSetting />}
        ></Route>
      </Route>
      <Route path={`${process.env.PUBLIC_URL}`} element={<LayoutNoSidebar />}>
        <Route path="auth-success" element={<Success />}></Route>
        <Route path="auth-reset" element={<ResetPassword />}></Route>
        <Route path="auth-register" element={<Register />}></Route>
        <Route index element={<Login />}></Route>
        <Route path="setPassword" element={<SetPassword />}></Route>
        <Route path="success" element={<SuccessMain />}></Route>
        <Route path="errorEmail" element={<EmailError />}></Route>

        <Route path="errors">
          <Route path="404-modern" element={<Error404Modern />}></Route>
          <Route path="404-classic" element={<Error404Classic />}></Route>
          <Route path="504-modern" element={<Error504Modern />}></Route>
          <Route path="504-classic" element={<Error504Classic />}></Route>
        </Route>
        <Route
          path="*"
          element={
            accessibility.length === 0 ? (
              <>
                <Flex align="center" gap="middle" className="pageLoader">
                  <Spin size="large" className="m-auto" />
                </Flex>
              </>
            ) : (
              <Error404Modern />
            )
          }
        ></Route>

        <Route
          path="invoice-print/:invoiceId"
          element={<InvoicePrint />}
        ></Route>
      </Route>
    </Routes>
  );
};
export default Pages;
