import React, { useState, useEffect, FC, useCallback } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Content from "../../layout/content/Content";
import { Card, Badge, Row, Col, Button, Modal, ModalBody } from "reactstrap";
import Head from "../../layout/head/Head";
import {
    BlockBetween,
    BlockHead,
    BlockHeadContent,
    BlockTitle,
    Icon,
} from "../../components/Component";
import { Controller, FormProvider, useForm } from "react-hook-form";
import {
    PaperClipOutlined,
    DownloadOutlined,
    PlusCircleOutlined,
    MinusCircleOutlined,
    DeleteOutlined,
    UploadOutlined,
    PlusSquareOutlined,
    EditOutlined,
    CheckCircleOutlined,
    CloudUploadOutlined
} from "@ant-design/icons";
import {
    Form,
    Input,
    InputNumber,
    Table,
    Collapse as AntCollapse,
    Button as AntButton,
    Descriptions,
    Modal as AntModal,
    Spin,
    Flex,
    Drawer,
    Upload,
    Select,
    Popover,
    DatePicker,
    TableColumnsType,
    Timeline,
    Empty,
    UploadFile,
    List,
    Checkbox
} from "antd";
import FormSelect from "../form-components/SelectComponent";
import FormInput from "../form-components/InputComponent";
import FormDatePicker from "../form-components/DatePicker";
import dayjs from "dayjs";
import axios from "axios";
import QuotationsAside from "./QuotationsAside";
import statusMapper from "../purchase-orders/StatusMapper";
// import QuotationApprovalDrawer from "../invoice/QuotationApprovalDrawer";
import { nanoid } from "nanoid";
import Misc from "../masters/Misc";
import ReactQuill from "react-quill";
import { Option } from "antd/es/mentions";
import invoiceApis from "../../api/Invoice/invoice";
import Swal from "sweetalert2";
import CropperComponent from "../components/CropperComponent";
import SignatureModal from "../purchase-orders/SignatureModal";
import usersApis from "../../api/master/users";
import quotationApis from "../../api/master/quotations";
import { useLocation } from "react-router-dom";
// import QuotationApprovalDrawer from "./newQuotations/QuotationApprovalDrawer";
// import { AddressDetails, Material, SelectOptions } from "./newQuotations/types";
import Dragger from "antd/es/upload/Dragger";
import costCenterApis from "../../api/master/costCenter";
// import deliveryAddressApis from "../../api/master/deliveryAddress";
import MaterialModal from "./MaterialModal";
import supplierApis from "../../api/master/supplier";

interface SelectOptions {
    label: string;
    value: string;
}


interface AddressDetails {
    id: any;
    addressLine1: string;
    addressLine2: string;
    cityName: string;
    stateName: string;
    countryName: string;
    pincode: string;
}

const EditableCell = ({
    editing,
    dataIndex,
    title,
    inputType,
    record,
    index,
    children,
    ...restProps
}: any) => {
    return (
        <td {...restProps}>
            {editing ? (
                <Form.Item
                    name={dataIndex}
                    style={{ margin: 0 }}
                    rules={[
                        {
                            validator: async (_: any, value: any) => {
                                // Special case for IGST: it can be 0
                                if (dataIndex === "igst" && value === 0) {
                                    return Promise.resolve();
                                }
                                if (dataIndex === "minQty" && value === 0) {
                                    return Promise.resolve();
                                }
                                // For all other fields, ensure the value is greater than 0
                                if (!value || value <= 0) {
                                    return Promise.reject(new Error(`${title} must be greater than 0`));
                                }
                                return Promise.resolve();
                            },
                        },
                    ]}
                >
                    <InputNumber
                        className="w-100"
                        formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                        parser={(value: any) => value.replace(/\$\s?|(,*)/g, "")}
                        onKeyPress={(event) => {
                            if (!/^[0-9]+$/.test(event.key)) {
                                event.preventDefault();
                            }
                        }}
                    />
                </Form.Item>
            ) : (
                children
            )}
        </td>
    );
};



const { Panel } = AntCollapse;

const { FormatCurrency, beforeUpload } = Misc;
type Cess = {
    cessName: string;
    cessType?: string;
    cessPercentage?: string;
    cessAmount?: string;
};
interface TaxConfiguration {
    taxType: string;
    gstType?: string; //IGST or CGST+SGST
    cess?: Cess[];
}

interface CurrencyData {
    description: string;
    currency_code: string;
    currency_symbol: string;
    currency_format: string;
    example: string;
}

const EditQuotedView: FC<any> = () => {
    const [isLoading, setIsLoading] = useState(true);
    let { qid }: any = useParams();
    const navigate = useNavigate();
    const [OriginalData, setOriginalData] = useState<any>({});
    const [rfqStatus, setRfqStatus] = useState<any>("");
    const [sm, setSm] = useState(false);
    const [tab, setTab] = useState("1");
    const [screenSize, setScreenSize] = useState(0);
    const [mobileView, setMobileView] = useState(false);
    const [taxConfiguration, setTaxConfiguration] = useState<TaxConfiguration>();
    const [instructionsVisible, setInstructionsVisible] = useState(false);
    const [termsVisible, setTermsVisible] = useState(false);
    const [materialData, setMaterialData] = useState<any>({});
    const [supplierList, setSupplierList] = useState<any>([]);
    const [attachments, setAttachments] = useState<any>([]);
    const [reqDocAttachments, setReqDocAttachments] = useState<any>([]);

    const [deletedAttachments, setDeletedAttachments] = useState<any>([]);
    const [newAttachments, setNewAttachments] = useState<any>([]);
    const [DMS, setDMS] = useState<any>([]);
    const [editLineRow, setEditLineRow] = useState<any>();
    const [generalInformationEdit, setGeneralInformationEdit] = useState(false);
    const [termsAndConditions, setTermsAndConditions] = useState<any>("");
    const [newTermsAndConditions, setNewTermsAndConditions] = useState<any>("");
    const [instructions, setInstructions] = useState("");
    const [newInstructions, setNewInstructions] = useState<any>("");
    const [isInstructionsChanged, setIsInstructionsChanged] = useState(false);
    const [currentUserId, setCurrentUserId] = useState<any>(null);
    const [approverIds, setApproverIds] = useState<any>([]);
    const [isTermsAndConditionsChanged, setIsTermsAndConditionsChanged] =
        useState(false);

    const methods = useForm({
        mode: "onChange",
    });

    const [signature, setSignature] = useState<any>([]);

    const {
        handleSubmit,
        reset,
        watch,
        setValue,
        formState: { errors },
    } = methods;

    const [addNewRowModel, setAddNewRowModel] = useState<boolean>(false);
    const [data, setData] = useState<any>([]);
    const [showSubmitBtm, setShowSubmitBtm] = useState(false);


    const [sitesOptions, setSitesOptions] = useState<SelectOptions[]>([]);
    const [costCenterOptions, setCostCenterOptions] = useState<SelectOptions[]>([]);
    const [billingAddressList, setBillingAddressList] = useState<AddressDetails[]>([]);
    const [billingAddressOptions, setBillingAddressOptions] = useState<SelectOptions[]>([]);
    const [suppliers, setSuppliers] = useState<any[]>([]);
    const [selectedArrayData, setSelectedArrayData] = useState([]);

    const [currency, setCurrency] = useState<CurrencyData>({
        description: "",
        currency_code: "",
        currency_symbol: "",
        currency_format: "",
        example: "",
    });

    const [editingKey, setEditingKey] = useState<any | null>(null);


    const [updatedLineItems, setUpdatedLineItems] = useState<any>([]);

    const [editedData, setEditedData] = useState<any[]>([]);
    const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);

    const [newReqAttachment, setNewReqAttachment] = useState<File[]>([]);

    const [fileNameMapping, setFileNameMapping] = useState<Map<string, string>>(new Map());

    const isEditing = (record: any) => record.key == editingKey;

    const [form] = Form.useForm();
    const [form2] = Form.useForm();



    const [additionalCharges, setAdditionalCharges]: any = useState<any>([]);
    const [editAdditionalCharges, setEditAdditionalCharges] =
        useState<any>(false);


    const [newNegotiableType, setNewNegotiableType] = useState<any>("");

    const [newValidityDate, setNewValidityDate] = useState<any>();
    const [newPaymentType, setNewPaymentType] = useState<any>("");
    const [newPaymentDays, setNewPaymentDays] = useState<any>("");
    const [newPaymentPercentage, setNewPaymentPercentage] = useState<any>("");
    useEffect(() => {
        fetchDetails();
        // fetchDeliveryAddress();
        fetchCostCenter();
        fetchSites();
        viewChange();

        window.addEventListener("load", viewChange);
        window.addEventListener("resize", viewChange);
        const headerClick = () => setSm(false);
        document
            .getElementsByClassName("nk-header")[0]
            ?.addEventListener("click", headerClick);

        return () => {
            window.removeEventListener("resize", viewChange);
            window.removeEventListener("load", viewChange);
            document
                .getElementsByClassName("nk-header")[0]
                ?.removeEventListener("click", headerClick);
        };
    }, []);

    const viewChange = () => {
        setScreenSize(window.innerWidth);
        if (window.innerWidth < 990) {
            setMobileView(true);
        } else {
            setMobileView(false);
            setSm(false);
        }
    };

    const handleAttachmentChange = (info: { fileList: UploadFile[] }) => {
        setNewAttachments((pre: any) => {
            return [...pre, ...info.fileList];
        });
        setShowSubmitBtm(true);
    };

    const fetchHtmlContentFromUrl = async (url: string) => {
        try {
            const response = await axios.get(url, {
                headers: {
                    "Content-Type": "text/html",
                },
            });

            return response?.data;
        } catch (error) {
            console.log(error);
            return null;
        }
    };


    const fetchSuppliers = async () => {
        try {
            const { data, status, message } = await supplierApis.fetchSupplier();
            console.log("status:", status);
            if (status) {
                console.log("1111111::::");
                let formatedData = data.map((item: any) => ({
                    supplierName: item.supplierName, // Adjust this based on the actual key in your data
                    supplierID: item?.supplierOrgID ? item?.supplierOrgID?.toString() : item?.supplierID?.toString(),
                }));
                console.log("formatedData", formatedData)
                setSuppliers(formatedData);
                return formatedData;
            } else {
                throw new Error(message);
            }
        } catch (error) {

        }
    }

    // const fetchDeliveryAddress = async () => {
    //     try {
    //       const { data, status } = await deliveryAddressApis.fetchDeliveryAddress();
    //       if (status) {
    //         let deliveryAddressOptions = data.map((item: any) => ({
    //           label: item.deliveryLocationName,
    //           value: item.id.toString(),
    //         }));
    //         setDeliveryAddressOptions(deliveryAddressOptions);
    //         setDeliveryAddressList(data);
    //       }
    //     } catch (error) {
    //       console.log(error);
    //     }
    //   };

    const fetchDetails = async () => {
        // fetchSuppliers();
        try {
            const res = await quotationApis.fetchQuotedQuotationId(qid);
            const { response, status, message } = res;
            if (status) {
                const data = response.data
                setOriginalData(data.supplierInfo);
                console.log("data", data)
                setRfqStatus(data?.status);
                console.log("rfqStatus", data?.supplierInfo?.supplierHeader.status);


                if (data?.supplierInfo.supplierHeader.additionalChargesArray) {
                    setAdditionalCharges(
                        data?.supplierInfo.supplierHeader.additionalChargesArray?.map((charge: any) => {
                            const id = nanoid(4);
                            form2.setFieldsValue({
                                [`AdditionalCharges.${id}.name`]: charge?.name,
                                [`AdditionalCharges.${id}.price`]: Number(charge?.price),
                            });
                            return {
                                ...charge,
                                id,
                            };
                        })
                    );
                }

                if (data?.supplierInfo?.supplierApprovals) {
                    let ids = data?.supplierInfo?.supplierApprovals?.map((item: any) => item?.userId);
                    ids = ids?.filter((item: any) => item?.status != "Approved");
                    setApproverIds(ids);
                }

                if (data?.supplierInfo?.supplierHeader?.dms?.length > 0) {
                    setDMS(data?.supplierInfo?.supplierHeader?.dms);
                    const _termsAndConditions = data?.supplierInfo?.supplierHeader?.dms?.find(
                        (item: any) => item.moduleFileType == "termsAndConditions"
                    );
                    if (_termsAndConditions) {
                        const data: any = await fetchHtmlContentFromUrl(
                            _termsAndConditions?.filePath
                        );
                        setTermsAndConditions(data);
                        setNewTermsAndConditions(data);
                    }
                    const _signature = data?.supplierInfo?.supplierHeader?.dms?.find(
                        (item: any) => item.moduleFileType == "signature"
                    );
                    if (_signature) {
                        setSignature({
                            docId: _signature?.docId,
                            fileName: _signature?.fileName,
                            image: _signature?.filePath,
                            moduleFileType: _signature?.moduleFileType,
                            module: _signature?.module,
                            label: data?.quotationHeader?.signatureLabel,
                            isActive: true,
                        });
                        // setNewInstructions(data);
                    }
                    const _attachments = data?.supplierInfo?.supplierHeader?.dms?.filter(
                        (item: any) => item.moduleFileType == "attachments"
                    );
                    console.log("_attachments", _attachments);
                    if (_attachments) {
                        console.log("11::::setAttachments")
                        setAttachments(
                            _attachments.map((attachment: any) => ({
                                docId: attachment?.docId,
                                fileName: attachment?.fileName,
                                link: attachment?.filePath,
                                moduleFileType: attachment?.moduleFileType,
                                module: attachment?.module,
                                label: "attachments",
                                isActive: true,
                            }))
                        );
                    }
                }


                if (data?.supplierInfo?.supplierDocs?.length > 0) {
                    // Access the dms arrays within supplierDocs
                    const dmsArrays = data?.supplierInfo?.supplierDocs?.map((doc: any) => doc.dms).flat();
                    console.log("dmsArrays", dmsArrays)


                    const docsMap = new Map();
                    data?.supplierInfo?.supplierDocs?.forEach((doc: any) => {
                        if (doc.dms) {
                            doc.dms.forEach((dmsItem: any) => {
                                docsMap.set(dmsItem.docId, doc.requiredDocs);
                            });
                        }
                    });

                    const updatedDms = dmsArrays.map((dmsItem: any) => ({
                        ...dmsItem,
                        requiredDocs: docsMap.get(dmsItem.docId) || ''
                    }));


                    console.log("docsMap", docsMap);

                    console.log("updatedDms", updatedDms)



                    const _recDocAttachments = updatedDms?.filter(
                        (item: any) => item.moduleFileType == "Supplier Docs"
                    );
                    console.log("_recDocAttachments", _recDocAttachments)
                    if (_recDocAttachments) {
                        const transformedAttachments = _recDocAttachments.map((attachment: any) => ({
                            docId: attachment?.docId,
                            name: attachment?.fileName,
                            link: attachment?.filePath,
                            moduleFileType: attachment?.moduleFileType,
                            module: attachment?.module,
                            label: 'Supplier Docs',
                            requiredDocsName: attachment.requiredDocs,
                            isActive: true
                        }));
                        console.log("DMS:::LOGS:::", transformedAttachments)
                        setReqDocAttachments(transformedAttachments);
                    }
                }

                setMaterialData(data);
                const LineItems = data?.supplierInfo.supplierLines;
                setData(LineItems);
                setShowSubmitBtm(false);
                setDeletedAttachments([]);
                setNewAttachments([]);
                // setCurrency({
                //     description: currencyCode,
                //     currency_code: currencyCode,
                //     currency_symbol: currencySymbol,
                //     currency_format: currencyFormat,
                //     example: currencyCode,
                // });
            }
            setIsLoading(false);
        } catch (error) {
            console.log(error);
        }
    }


    const ViewTableTotals = ({ data, additionalCharges, invoice }: any) => {
        console.log("Line Total::::",data)
        let totalNetAmmount = 0,
            cgst = 0,
            sgst = 0,
            igst = 0,
            TotalAmount = 0;
        data?.forEach((item: any) => {
            const totalAmmount = Number(item.suppliedQty) * Number(item.price);
            cgst = cgst + (totalAmmount * Number(item.cgst)) / 100;
            sgst = sgst + (totalAmmount * Number(item.sgst)) / 100;
            igst = igst + (totalAmmount * Number(item.igst)) / 100;
            totalNetAmmount = + totalAmmount;
        });
        TotalAmount = totalNetAmmount + cgst + sgst + igst;
        additionalCharges?.forEach((item: any) => {
            TotalAmount = TotalAmount + Number(item.price);
        });
        return (
            <Row
                className={`${invoice && "w-50 mt-2"}`}
                style={{
                    margin: "20px",
                }}
            >
                <Col className={`justify-end`}>
                    <div className={`${invoice ? "w-100" : "w-40"}`}>
                        <div className="d-flex justify-content-between">
                            <div className="w-50 justify-end">
                                <strong>Taxable Amount</strong>
                            </div>
                            <span className="r_align">
                                {FormatCurrency(
                                    totalNetAmmount,
                                    "INR"
                                )}
                            </span>
                        </div>
                        <div className="d-flex justify-content-between">
                            <div className="w-50 justify-end">
                                <strong>CGST</strong>
                            </div>
                            <span className="r_align">
                                {FormatCurrency(
                                    cgst,
                                    "INR"
                                )}
                            </span>
                        </div>
                        <div className="d-flex justify-content-between">
                            <div className="w-50 justify-end">
                                <strong>SGST</strong>
                            </div>
                            <span className="r_align">
                                {FormatCurrency(
                                    sgst,
                                    "INR"
                                )}
                            </span>
                        </div>
                        <div className="d-flex justify-content-between">
                            <div className="w-50 justify-end">
                                <strong>IGST</strong>
                            </div>
                            <span className="r_align">
                                {FormatCurrency(
                                    igst,
                                    "INR"
                                )}
                            </span>
                        </div>
                        {additionalCharges?.map((item: any) => {
                            if (!item.price || !item.price) return null;
                            return (
                                <div className="d-flex justify-content-between">
                                    <div className="w-50 justify-end">
                                        <strong>{item.name}</strong>
                                    </div>
                                    <span className="r_align">
                                        {FormatCurrency(
                                            item.price,
                                            "INR"
                                        )}
                                    </span>
                                </div>
                            );
                        })}
                        <div
                            className="d-flex justify-content-between"
                            style={{ fontSize: "18px", marginTop: "10px" }}
                        >
                            <div className="w-50 justify-end">
                                <strong>Total</strong>
                            </div>
                            <strong className="r_align">
                                {FormatCurrency(
                                    TotalAmount,
                                    "INR"
                                )}
                            </strong>
                        </div>
                    </div>
                </Col>
            </Row>
        );
    };


    const fetchCostCenter = async () => {
        try {
            const { data, status } = await costCenterApis.fetchCostCenter();
            if (status) {
                const costCenterOptions = data.map((item: any) => ({
                    label: item.costCenterName,
                    value: item.id.toString(),
                }));
                setCostCenterOptions(costCenterOptions);
            }
        } catch (error) {
            console.log(error);
        }
    };


    const fetchAddresses = async () => {
        try {
            // const { data, status } = await deliveryAddressApis.fetchDeliveryAddress();
            // if (status) {
            //     const addressOptions = data.map((item: any) => ({
            //         label: item.deliveryLocationName,
            //         value: item.id.toString(),
            //     }));
            //     setBillingAddressList(data);
            //     setBillingAddressOptions(addressOptions);
            // }
        } catch (error) {
            console.log(error);
        }
    };


    const fetchSites = async () => {
        try {
            const data = await usersApis.getCurrentUser()
            if (data) {
                let sitesOptions = data?.siteAccessibility?.map((item: any) => ({
                    label: item.siteName,
                    value: item.siteID.toString(),
                }));
                setSitesOptions(sitesOptions);
            }
        } catch (error) {
            console.log(error);
        }
    };

    const edit = (record: any) => {
        console.log("record",record)
        form.setFieldsValue({
            suppliedQty: Number(record.suppliedQty),
            minQty: Number(record.minQty),
            hsn: record.hsn,
            price: Number(record.price),
            sgst: Number(record.sgst),
            cgst: Number(record.cgst),
            igst: Number(record.igst),
            leadDays: Number(record.leadDays),
            ...record
        });
        console.log("key", form,record.key)
        setEditingKey(record.key);
    };

    const save = async (key: React.Key) => {
        console.log("Save", key)
        try {
            console.log("Try")
            const row: any = await form.validateFields();
            console.log("row",row)
            const newData: any = [...data];
            console.log("newData", newData)
            const index = newData.findIndex((item: any) => key == item.id);
            if (index > -1) {
                setUpdatedLineItems((pre: any) => {
                    if (!pre.includes(key)) {
                        return pre.concat(key);
                    }
                    return pre;
                });
                const item: any = newData[index];
                console.log("row", row)
                const quantityInvoiced = row?.quantityInvoiced;
                const unitPrice = Number(row.unitPrice);
                const basicAmount = quantityInvoiced * unitPrice;
                const sgst = Number(row.sgst);
                const igst = Number(row.igst);
                const cgst = Number(row.cgst);
                const sgstData = (basicAmount * Number(row.sgst)) / 100;
                const igstData = (basicAmount * Number(row.igst)) / 100;
                const cgstData = (basicAmount * Number(row.cgst)) / 100;
                const hsn = row.hsn;
                const leadDays = row.leadDays;
                const minQty = row.minQty;
                const price = row.price;
                const suppliedQty = row.suppliedQty;
                const lineTotal = basicAmount + sgstData + igstData + cgstData;
                console.log("lineTotal",lineTotal)
                const updatedRow = {
                    ...item,
                    quantityInvoiced,
                    unitPrice,
                    basicAmount,
                    sgst,
                    igst,
                    cgst,
                    sgstData,
                    igstData,
                    cgstData,
                    hsn,
                    leadDays,
                    minQty,
                    price,
                    suppliedQty,
                    grossTotal: basicAmount + sgst + igst + cgst,
                    lineTotal
                };
                console.log(index);
                console.log(updatedRow);

                newData.splice(index, 1, { ...updatedRow });
                setData(newData);
                setEditingKey(null);
                setEditedData(
                    newData.filter((item: any) => selectedRowKeys.includes(item.quotationLineId))
                );
            }
            setShowSubmitBtm(true);
        } catch (errInfo) {
            console.log("Validate Failed:", JSON.stringify(errInfo, null, 2));

        }
    };



    const columns = [
        // {
        //     ...Table.EXPAND_COLUMN,
        //     fixed: "left",
        // },
        // {
        //     title: "MATERIAL ID",
        //     dataIndex: "materialId",
        //     key: "materialId",
        //     fixed: "left" as const,
        //     width: 120,
        // },
        {
            title: "CATEGORY",
            dataIndex: "categoryName",
            key: "categoryName",
            fixed: "left" as const,
            width: 150,
        },
        {
            title: "MATERIAL NAME",
            dataIndex: "materialName",
            key: "materialName",
            width: 200,
        },
        // {
        //     title: "DESCRIPTION",
        //     dataIndex: "description",
        //     key: "description",
        //     width: 200,
        // },
        {
            title: "UOM",
            dataIndex: "uomName",
            key: "uomName",
            width: 150,
        },
        {
            title: "QTY",
            dataIndex: "quantity",
            key: "qty",
            width: 150,
        },
        {
            title: "Expected Delivery Date",
            dataIndex: "needByDate",
            width: 200,
            render: (text: any, record: any) =>
                `${dayjs(text).format("DD-MMM-YYYY")}`,
        },
        {
            title: "Supply Qty",
            dataIndex: `suppliedQty`,
            key: "suppliedQty",
            width: 120,
            editable: true,
        },
        {
            title: "Min Purchase Qty",
            dataIndex: `minQty`,
            key: "minQty",
            width: 150,
            editable: true,
        },
        {
            title: "HSN",
            dataIndex: `hsn`,
            key: "hsn",
            width: 120,
            // editable: true,
        },
        {
            title: "Price",
            dataIndex: "price",
            key: "price",
            width: 150,
            editable: true,
        },
        {
            title: "SGST (%)",
            dataIndex: "sgst",
            key: "sgst",
            width: 200,
            editable: true,
        },
        {
            title: "CGST (%)",
            dataIndex: "cgst",
            key: "cgst",
            width: 200,
            editable: true,
        },
        {
            title: "IGST (%)",
            dataIndex: "igst",
            key: "igst",
            width: 200,
            editable: true,
        },
        {
            title: "Lead Days",
            dataIndex: "leadDays",
            key: "leadDays",
            width: 250,
            editable: true,
        },
        {
            title: "Line Total",
            dataIndex: "lineTotal",
            key: "lineTotal",
            width: 250,
        },
        {
            title: "Actions",
            key: "actions",
            width: 100,
            fixed: "right",
            render: (_: any, record: any) => {
                const editable = isEditing(record);
                console.log("isEditing:::::0000", editable);
                return editable ? (
                    <div className="w-100 justify-content-around">
                        <Button
                            size="sm"
                            color=""
                            onClick={() => save(record.id)}
                            style={{ marginRight: 8 }}
                        >
                            <Icon name="save" />
                        </Button>

                        <Button size="sm" color="" onClick={cancel}>
                            <Icon name="cross" />
                        </Button>
                    </div>
                ) : (
                    <Button size="sm" color="" onClick={() => edit(record)}>
                        <Icon name="edit" />
                    </Button>
                );
            },
        },
    ];



    const cancel = () => {
        setEditingKey(null);
    };

    useEffect(() => {
        if (editLineRow) {
            setAddNewRowModel(true);
        }
    }, [editLineRow]);



    const ApprovalTimelineContent = () => {
        const approvalsData = OriginalData?.supplierApprovals;
        console.log("ApprovalData", OriginalData)
        if (!approvalsData || approvalsData?.length === 0) {
            return <Badge color="outline-danger">No</Badge>;
        }

        return (
            <Popover
                content={
                    <Timeline className="approval-timeline">
                        {approvalsData?.map((approval: any, index: number) => {
                            const isApproved = approval.status == "Approved";
                            return (
                                <Timeline.Item
                                    key={index}
                                    dot={
                                        isApproved ? (
                                            <CheckCircleOutlined style={{ color: "green" }} />
                                        ) : undefined
                                    }
                                >
                                    {approval?.userName}
                                    <div className="designation">{approval?.roleName}</div>
                                </Timeline.Item>
                            );
                        })}
                    </Timeline>
                }
                title={<span style={{ color: "#2263b3" }}>Approval Hierarchy</span>}
            >
                <span
                    style={{
                        color: "#2263b3",
                        textDecoration: "underline",
                        cursor: "pointer",
                    }}
                >
                    <Badge color="outline-success">Yes</Badge>
                </span>
            </Popover>
        );
    };


    const billingAddressContent = OriginalData?.quotationInfo?.quotationHeader?.billingAddressName ? (
        <div className="billingAddress_align">
            <p>
                {OriginalData?.quotationInfo?.quotationHeader?.billingAddressName.addressLine1},{" "}
                {OriginalData?.quotationInfo?.quotationHeader?.billingAddressName.addressLine2}
            </p>
            <p>
                {OriginalData?.quotationInfo?.quotationHeader?.billingAddressName?.city},{" "}
                {OriginalData?.quotationInfo?.quotationHeader?.billingAddressName?.districtName},{" "}
                {OriginalData?.quotationInfo?.quotationHeader?.billingAddressName?.stateName},{" "}
                {OriginalData?.quotationInfo?.quotationHeader?.billingAddressName?.countryName} -{" "}
                {OriginalData?.quotationInfo?.quotationHeader?.billingAddressName?.pincode}
            </p>
        </div>
    ) : null;

    const orderDeliveryAddressContent = OriginalData?.quotationInfo?.quotationHeader?.deliveryAddressName ? (
        <div className="billingAddress_align">
            <p>
                {OriginalData?.quotationInfo?.quotationHeader?.deliveryAddressName?.addressLine1},{" "}
                {OriginalData?.quotationInfo?.quotationHeader?.deliveryAddressName?.addressLine2}
            </p>
            <p>
                {OriginalData?.quotationInfo?.quotationHeader?.deliveryAddressName?.cityName},{" "}
                {OriginalData?.quotationInfo?.quotationHeader?.deliveryAddressName?.districtName},{" "}
                {OriginalData?.quotationInfo?.quotationHeader?.deliveryAddressName?.stateName},{" "}
                {OriginalData?.quotationInfo?.quotationHeader?.deliveryAddressName?.countryName} -{" "}
                {OriginalData?.quotationInfo?.quotationHeader?.deliveryAddressName?.pincode}
            </p>
        </div>
    ) : null;



   

    const uploadProps = {
        beforeUpload: (file: File) => {
            // Update file name mapping with the requiredDocsName
            console.log("file", file, reqDocAttachments)
            const attachment = reqDocAttachments.find((doc: any) => doc.name === file.name);
            if (attachment) {
                setFileNameMapping(prevMapping => new Map(prevMapping).set(file.name, attachment.requiredDocsName));
            }
            setNewReqAttachment(prevFiles => [...prevFiles, file]);
            setShowSubmitBtm(true);
            return false; // Prevent auto-upload
        },
        maxCount: 1, // Adjust according to your needs
    };




    const SubmitChanges = async () => {
        console.log("newReqDoceAttachment", newReqAttachment);
        // return
        try {
            const _formData = new FormData();
            const _updatedLineItems: any = [];
            const updatedLineItems: any = [];
            const newLineItems: any = [];
            const deletedLineItems: any = [];
            // const deletedFileData: any = [];


            const newfiledata: any = [];
            const deletedFileData: any = [];
            const updatedFileData: any = [];


            data?.forEach((item: any) => {
                if (!item?.quotationLineId) {
                    // new item
                    newLineItems.push(item);
                }
            });



            newAttachments.forEach((attachment: any, index: number) => {
                if (!attachment) {
                    return;
                }
                console.log("attachment", attachment)
                const file = new File([attachment], attachment.name, {
                    type: attachment.type,
                    lastModified: attachment.lastModified,
                });

                _formData.append("files", file);
                newfiledata.push({
                    fileIndex: newfiledata.length,
                    module: "Quotations",
                    moduleFileType: "attachments",
                    level: "HEADER_LEVEL",
                });
            });
            console.log("isTermsAndConditionsChanged", isTermsAndConditionsChanged);

            if (isTermsAndConditionsChanged) {
                const termsAndConditionsFile = new Blob([newTermsAndConditions], {
                    type: "text/html",
                });
                _formData.append(
                    `files`,
                    new File([termsAndConditionsFile], "termsAndConditions.html", {
                        type: "text/html",
                    })
                );
                newfiledata.push({
                    fileIndex: newfiledata.length,
                    module: "quotations",
                    moduleFileType: "termsAndConditions",
                    level: "HEADER_LEVEL",
                });
                setIsTermsAndConditionsChanged(false);
                const _termsAndConditions = DMS?.find(
                    (item: any) => item.moduleFileType == "termsAndConditions"
                );
                if (_termsAndConditions?.docId) {
                    deletedFileData.push({
                        docId: _termsAndConditions.docId,
                    });
                }
            }

            newReqAttachment.forEach(file => {
                const requiredDocsName = fileNameMapping.get(file.name);
                newfiledata.push({
                    fileIndex: newfiledata.length,
                    module: "Quotations",
                    moduleFileType: "Supplier Docs",
                    level: "LINE_LEVEL",
                    requiredDocsName: requiredDocsName
                });
                _formData.append(
                    'files',
                    new File([file], file.name, { type: file.type })
                );
            })

            // const newAttachmentNames = newReqAttachment.map(file => file.name);
            // reqDocAttachments.forEach((attachment:any) => {
            //     if (!newAttachmentNames.includes(attachment.name)) {
            //         deletedFileData.push({
            //             docId: attachment.docId,
            //         });
            //     }
            // });

            const filesData = {
                newfiledata,
                deletedFileData,
                updatedFileData,
            };

            console.log("original data", OriginalData);

            if (additionalCharges) {
                OriginalData.supplierHeader.additionalChargesArray = additionalCharges?.map((charge: any) => {
                    return {
                        name: charge?.name,
                        price: charge?.price,
                    };
                })
            }

            if (newNegotiableType != "") {
                OriginalData.supplierHeader.negotiable = newNegotiableType;
                setNewNegotiableType("");
            }


            if (newValidityDate != "") {
                OriginalData.supplierHeader.validityDate = dayjs(newValidityDate).format("YYYY-MM-DD");
                setNewValidityDate("");
            }


            if (newPaymentType != "") {
                OriginalData.supplierHeader.paymentTerms = newPaymentType;
                setNewPaymentType("");
            }

            if (newPaymentDays != "") {
                OriginalData.supplierHeader.paymentDays = newPaymentDays;
                setNewPaymentDays("");
            }

            if (newPaymentPercentage != "") {
                OriginalData.supplierHeader.paymentPercentage = newPaymentPercentage;
                setNewPaymentPercentage("");
            }

            if (newLineItems) {
                OriginalData.supplierLines = data;
            }


            OriginalData.supplierLines.forEach((item: any) => {
                item.needByDate = dayjs(item.needByDate).format("YYYY-MM-DD");
            })


            _formData.append("filesData", JSON.stringify(filesData));

            // _formData.append("files", JSON.stringify([]));

            _formData.append(
                "supplierHeader",
                JSON.stringify(OriginalData.supplierHeader)
            );

            _formData.append(
                "supplierLines",
                JSON.stringify(OriginalData.supplierLines)
            );

            _formData.append(
                "supplierApprovals",
                JSON.stringify(OriginalData.supplierApprovals)
            );

            // return

            const res = await quotationApis.quoteNewVersion(_formData);
            const { status, message }: any = res;
            if (status === true) {
                fetchDetails();
                Swal.fire("Updated!", message, "success");
                navigate("/quoted_view");
            } else {
                Swal.fire("Error!", message, "error");
            }
        } catch (error) {
            console.log(error);
        }
    };

    const mergedColumns: any = columns.map((col) => {
        if (!col.editable) {
            return col;
        }
        return {
            ...col,
            onCell: (record: any) => ({
                record,
                inputType: col.dataIndex === "quantity" ? "number" : "text",
                dataIndex: col.dataIndex,
                title: col.title,
                editing: isEditing(record),
            }),
        };
    })



    const regretSupplier = async () => {
        console.log("regretSupplier", OriginalData);
        try {
            const res = await quotationApis.regretSupplier(OriginalData?.supplierHeader?.quotationId, OriginalData?.supplierHeader?.referencedSupplier?.referencedSupplierId);
            const { status, message, response }: any = res;
            if (status === true) {
                Swal.fire("Regretted!", message, "success");
                navigate("/quoted_view");
            } else {
                Swal.fire("Error!", response, "error");
            }
        } catch (error) {
            console.log(error);
        }
    }


    return (
        <>
            <Head title="View Quotation Details" />
            <Content>
                <Card className="card-bordered">
                    <div className="card-aside-wrap">
                        {isLoading == true ? (
                            <>
                                <Flex align="center" gap="middle" className="pageLoader">
                                    <Spin size="large" className="m-auto" />
                                </Flex>
                            </>
                        ) : (
                            <div className="card-inner card-inner-lg">
                                {sm && mobileView && (
                                    <div
                                        className="toggle-overlay"
                                        onClick={() => setSm(!sm)}
                                    ></div>
                                )}
                                <BlockHead size="lg">
                                    <BlockBetween>
                                        <BlockHeadContent>
                                            <BlockTitle tag="h4">
                                                <span>
                                                    RFQ - {OriginalData?.supplierHeader?.rfqId} |{" "}
                                                    {(statusMapper[rfqStatus] &&
                                                        statusMapper[rfqStatus]?.buyer?.title) ||
                                                        rfqStatus}{" "}
                                                </span>
                                            </BlockTitle>
                                        </BlockHeadContent>
                                        <div className="d-flex">
                                            {showSubmitBtm && (
                                                <div className="actions clearfix">
                                                    <ul
                                                        className="m-auto d-flex align-items-center justify-content-center p-0"
                                                        style={{
                                                            flexDirection: "row-reverse",
                                                        }}
                                                    >
                                                        <li className="pt-0 pb-0">
                                                            <Button
                                                                color="primary"
                                                                type="button"
                                                                onClick={() => {
                                                                    Swal.fire({
                                                                        title: "Are you sure?",
                                                                        text: "Do you want to submit the changes?",
                                                                        icon: "warning",
                                                                        showCancelButton: true,
                                                                        confirmButtonColor: "#3085d6",
                                                                        cancelButtonColor: "#d33",
                                                                        confirmButtonText: "Yes, submit it!",
                                                                        cancelButtonText: "No, cancel",
                                                                    }).then(async (result) => {
                                                                        if (result.isConfirmed) {
                                                                            await SubmitChanges();
                                                                        }
                                                                    });
                                                                }}
                                                            >
                                                                Submit
                                                            </Button>
                                                        </li>
                                                        <li className="pt-0 pb-0">
                                                            <Button
                                                                color="secondary"
                                                                onClick={() => {
                                                                    Swal.fire({
                                                                        title: "Are you sure?",
                                                                        text: "Do you want to revert the changes?",
                                                                        icon: "warning",
                                                                        showCancelButton: true,
                                                                        confirmButtonColor: "#3085d6",
                                                                        cancelButtonColor: "#d33",
                                                                        confirmButtonText: "Yes, revert it!",
                                                                        cancelButtonText: "No, cancel",
                                                                    }).then((result) => {
                                                                        if (result.isConfirmed) {
                                                                            fetchDetails();
                                                                            Swal.fire(
                                                                                "Reverted!",
                                                                                "Your changes have been reverted.",
                                                                                "success"
                                                                            );
                                                                        }
                                                                    });
                                                                }}
                                                                type="button"
                                                                className="btn-dim btn btn-secondary"
                                                            >
                                                                Revert
                                                            </Button>
                                                        </li>
                                                    </ul>
                                                </div>
                                            )}

                                            {!showSubmitBtm && (
                                                <div className="actions clearfix">

                                                    <ul
                                                        className="m-auto d-flex align-items-center justify-content-center p-0"
                                                        style={{
                                                            flexDirection: "row-reverse",
                                                        }}
                                                    >

                                                        <li className="pt-0 pb-0">
                                                            <Button
                                                                color="secondary"
                                                                onClick={() => {
                                                                    Swal.fire({
                                                                        title: "Are you sure?",
                                                                        text: "Do you want to Regretted?",
                                                                        icon: "warning",
                                                                        showCancelButton: true,
                                                                        confirmButtonColor: "#3085d6",
                                                                        cancelButtonColor: "#d33",
                                                                        confirmButtonText: "Yes, Regret!",
                                                                        cancelButtonText: "No, cancel",
                                                                    }).then((result) => {
                                                                        if (result.isConfirmed) {
                                                                            regretSupplier();
                                                                            // Swal.fire(
                                                                            //     "Regretted!",
                                                                            //     "Your changes have been reverted.",
                                                                            //     "success"
                                                                            // );
                                                                        }
                                                                    });
                                                                }}
                                                                type="button"
                                                                className="btn-dim btn btn-secondary"
                                                            >
                                                                Regret
                                                            </Button>
                                                        </li>
                                                        <li className="pt-0 pb-0">
                                                            <Button
                                                                className="toggle"
                                                                color="primary"
                                                                onClick={async () => {
                                                                    navigate("/quoted_view");
                                                                }}
                                                            >
                                                                <Icon name="arrow-left" />
                                                                <span>Back</span>
                                                            </Button>
                                                        </li>
                                                    </ul>
                                                </div>
                                            )}
                                        </div>
                                        <BlockHeadContent className="align-self-start d-lg-none">
                                            <Button
                                                className={`toggle btn btn-icon btn-trigger mt-n1 ${sm ? "active" : ""
                                                    }`}
                                                onClick={() => setSm(!sm)}
                                            >
                                                <Icon name="menu-alt-r" />
                                            </Button>
                                        </BlockHeadContent>
                                    </BlockBetween>
                                </BlockHead>
                                <div className="content clearfix">
                                    <AntCollapse defaultActiveKey={["1"]}>
                                        <Panel header={<div style={{ display: 'flex', justifyContent: "space-between", alignItems: "center", width: "100%" }}>
                                            <span>General Details</span>
                                        </div>
                                        } key="1">
                                            <Descriptions title="" className="custom-description-title">
                                                <Descriptions.Item label="Quotation For">
                                                    {materialData?.supplierInfo?.quotationInfo?.quotationHeader?.quotationFor}
                                                </Descriptions.Item>
                                                <Descriptions.Item label="Description">
                                                    {materialData?.supplierInfo?.quotationInfo?.quotationHeader?.quotationDescription}
                                                </Descriptions.Item>
                                                <Descriptions.Item label="Site Name">
                                                    {materialData?.supplierInfo?.quotationInfo?.quotationHeader?.siteName}
                                                </Descriptions.Item>
                                                {/* <Descriptions.Item label="Last Submission Date">
                                                    <span className="badge-dim badge bg-primary rounded-pill">
                                                        <strong> {materialData?.supplierInfo?.quotationInfo?.quotationHeader?.lastSubmissionDate && dayjs(OriginalData?.quotationHeader?.lastSubmissionDate).format("DD-MMM-YYYY")}
                                                        </strong>
                                                    </span>
                                                </Descriptions.Item> */}
                                                {/* <Descriptions.Item label="Time Limit">
                                                    {materialData?.supplierInfo?.quotationInfo?.quotationHeader?.deadlineType}
                                                </Descriptions.Item> */}
                                                {/* <Descriptions.Item label="Site Name">
                                                    {materialData?.supplierInfo?.quotationInfo?.quotationHeader?.siteName}
                                                </Descriptions.Item> */}
                                                <Descriptions.Item label="Approvals">
                                                    {<ApprovalTimelineContent />}
                                                </Descriptions.Item>
                                                <Descriptions.Item label="Negotiable">
                                                    {/* {" "}
                                                    {materialData?.supplierInfo?.supplierHeader?.negotiable == 'Y' ? "Yes" : "No"} */}


                                                    <Form.Item
                                                        name="negotiable"
                                                        label={undefined}
                                                        className="w-50 paddingLeft10  p-0 m-0"
                                                        initialValue={materialData?.supplierInfo?.supplierHeader?.negotiable}
                                                    >
                                                        <Select
                                                            placeholder="Select Negotiable Type"
                                                            defaultValue={materialData?.supplierInfo?.supplierHeader?.negotiable}
                                                            onChange={(e) => {
                                                                console.log("e", e);
                                                                setNewNegotiableType(e);
                                                                setShowSubmitBtm(true);
                                                            }}
                                                        >
                                                            <Option value="Y">Yes</Option>
                                                            <Option value="N">No</Option>
                                                        </Select>
                                                    </Form.Item>



                                                </Descriptions.Item>
                                                <Descriptions.Item label="validity Date">
                                                    {/* {dayjs(materialData?.supplierInfo?.supplierHeader?.validityDate).format("DD-MM-YYYY")} */}
                                                    <Form.Item
                                                        name="validityDate"
                                                        label={undefined}
                                                        className="w-50 paddingLeft10  p-0 m-0"
                                                        initialValue={materialData?.supplierInfo?.supplierHeader?.validityDate}
                                                    >
                                                        <DatePicker
                                                            className="w-100"
                                                            placeholder="Invoice Due Date"
                                                            maxDate={dayjs().add(1, "day")}
                                                            allowClear={false}
                                                            defaultValue={dayjs(materialData?.supplierInfo?.supplierHeader?.validityDate)}
                                                            onChange={(e) => {
                                                                setNewValidityDate(e);
                                                                setShowSubmitBtm(true);
                                                            }}
                                                        />
                                                    </Form.Item>
                                                </Descriptions.Item>
                                                <Descriptions.Item label="Payment Terms">
                                                    <Form.Item
                                                        name="paymentTerms"
                                                        label={undefined}
                                                        className="w-50 paddingLeft10  p-0 m-0"
                                                        initialValue={materialData?.supplierInfo?.supplierHeader?.paymentTerms}
                                                    >
                                                        <Select
                                                            placeholder="Select Payment Type"
                                                            defaultValue={materialData?.supplierInfo?.supplierHeader?.paymentTerms}
                                                            onChange={(e) => {
                                                                // handelPaymetTypeChange(e);
                                                                setNewPaymentType(e);
                                                                setShowSubmitBtm(true);
                                                            }}
                                                        >
                                                            <Option value="Cash and Carry">Cash & Carry</Option>
                                                            <Option value="Advance">Advance</Option>
                                                            <Option value="Credit">Credit</Option>
                                                        </Select>
                                                    </Form.Item>
                                                </Descriptions.Item>
                                                {
                                                    ((materialData?.supplierInfo?.supplierHeader?.paymentTerms === "Credit" && newPaymentType == "") || newPaymentType === "Credit") && (
                                                        // <Descriptions.Item label="Payment Days">
                                                        //     {materialData?.supplierInfo?.supplierHeader?.paymentDays || "--"}
                                                        // </Descriptions.Item>
                                                        <Descriptions.Item label="Payment Days">
                                                            <Form.Item
                                                                name="paymentDays"
                                                                label={undefined}
                                                                initialValue={materialData?.supplierInfo?.supplierHeader?.paymentDays}
                                                            >
                                                                <Input
                                                                    defaultValue={materialData?.supplierInfo?.supplierHeader?.paymentDays}
                                                                    onChange={(e) => {
                                                                        setNewPaymentDays(e.target.value);
                                                                        setShowSubmitBtm(true);
                                                                    }}
                                                                    placeholder="Enter Payment Days" />
                                                            </Form.Item>
                                                        </Descriptions.Item>
                                                    )
                                                }

                                                {
                                                    ((materialData?.supplierInfo?.supplierHeader?.paymentTerms == "Advance" && newPaymentType == "") || newPaymentType === "Advance") && (
                                                        <Descriptions.Item label="Payment %">
                                                            <Form.Item
                                                                name="paymentPercentage"
                                                                label={undefined}
                                                                initialValue={materialData?.supplierInfo?.supplierHeader?.paymentPercentage}
                                                            >
                                                                <Input
                                                                    defaultValue={materialData?.supplierInfo?.supplierHeader?.paymentPercentage}
                                                                    onChange={(e) => {
                                                                        setNewPaymentPercentage(e.target.value);
                                                                        setShowSubmitBtm(true);
                                                                    }}
                                                                    placeholder="Enter Payment Percentage" />
                                                            </Form.Item>
                                                        </Descriptions.Item>
                                                    )
                                                }
                                                <Descriptions.Item label="Billing Address">
                                                    <Popover
                                                        content={billingAddressContent}
                                                        title={
                                                            <span style={{ color: "#2263b3" }}>Billing Address</span>
                                                        }
                                                    >
                                                        <span
                                                            style={{
                                                                color: "#2263b3",
                                                                textDecoration: "underline",
                                                                cursor: "pointer",
                                                            }}
                                                        >
                                                            Address
                                                            {
                                                                materialData?.supplierInfo?.quotationInfo?.quotationHeader?.billingAddressName
                                                                    ?.deliveryLocationName
                                                            }
                                                            {/* {
                                                            OriginalData?.quotationHeader?.billingAddressName
                                                        } */}
                                                        </span>
                                                    </Popover>
                                                </Descriptions.Item>
                                                {/* <Descriptions.Item label="Delivery Address">
                                                    {materialData?.supplierInfo?.quotationInfo?.quotationHeader?.deliveryLevelType === "LINE_LEVEL"
                                                        ? "Line Level"
                                                        : "Order Level"}
                                                </Descriptions.Item> */}

                                                {materialData?.supplierInfo?.quotationInfo?.quotationHeader?.deliveryLevelType === "ORDER_LEVEL" && (
                                                    <Descriptions.Item label="Delivery Address">
                                                        <Popover
                                                            content={orderDeliveryAddressContent}
                                                            title={
                                                                <span style={{ color: "#2263b3" }}>Delivery Address</span>
                                                            }
                                                        >
                                                            <span
                                                                style={{
                                                                    color: "#2263b3",
                                                                    textDecoration: "underline",
                                                                    cursor: "pointer",
                                                                }}
                                                            >
                                                                {
                                                                    materialData?.supplierInfo?.quotationInfo?.quotationHeader?.deliveryAddressName
                                                                        ?.deliveryLocationName
                                                                }
                                                            </span>
                                                        </Popover>
                                                    </Descriptions.Item>
                                                )}
                                            </Descriptions>
                                        </Panel>
                                        <Panel header="Materials" key="2">
                                            <div className="table-responsive poTable">
                                                <div
                                                    style={{
                                                        width: screenSize - 120 + "px",
                                                        margin: "0px auto",
                                                    }}
                                                >
                                                    <Form form={form} component={false}>
                                                        <Table
                                                            // columns={columns}
                                                            columns={mergedColumns.map((col: any) => {
                                                                return col;
                                                            })}
                                                            dataSource={data.map((item: any) => ({
                                                                ...item,
                                                                key: item.quotationLineId,
                                                            }))}
                                                            scroll={{ x: 1000 }}
                                                            components={{
                                                                body: {
                                                                    cell: EditableCell,
                                                                },
                                                            }}
                                                            bordered
                                                            size="middle"
                                                            pagination={false}
                                                        />
                                                    </Form>
                                                </div>
                                                <div className="d-flex">
                                                    <Form form={form2} component={false}>
                                                        <Row className="mt-2 w-50">
                                                            <div>
                                                                <div className="d-flex align-items-center">
                                                                    <h6 className="m-1 mb-0">
                                                                        Additional Charges (If Any)
                                                                    </h6>
                                                                    <div className="w-20 m-1 mb-0">
                                                                        {editAdditionalCharges ? (
                                                                            <a
                                                                                type={undefined}
                                                                                onClick={() => {
                                                                                    if (
                                                                                        additionalCharges.find(
                                                                                            (field: any, ind: number) => {
                                                                                                const name = form2.getFieldValue(
                                                                                                    `AdditionalCharges.${field?.id}.name`
                                                                                                );
                                                                                                const price = form2.getFieldValue(
                                                                                                    `AdditionalCharges.${field?.id}.price`
                                                                                                );
                                                                                                return !name || !price;
                                                                                            }
                                                                                        )
                                                                                    ) {
                                                                                        return;
                                                                                    }

                                                                                    setAdditionalCharges([
                                                                                        ...additionalCharges,
                                                                                        {
                                                                                            name: "",
                                                                                            price: null,
                                                                                            id: nanoid(4),
                                                                                        },
                                                                                    ]);
                                                                                }}
                                                                                className="addNewItem"
                                                                            >
                                                                                <PlusSquareOutlined />
                                                                            </a>
                                                                        ) : (
                                                                            <a
                                                                                type={undefined}
                                                                                onClick={() => {
                                                                                    setEditAdditionalCharges(true);
                                                                                }}
                                                                                className="addNewItem"
                                                                            >
                                                                                <EditOutlined />
                                                                            </a>
                                                                        )}
                                                                    </div>
                                                                </div>

                                                                {additionalCharges?.map(
                                                                    (field: any, index: number) => {
                                                                        return (
                                                                            <Row key={field.id} className="m-2 mb-0 w-100">
                                                                                <div className="w-50">
                                                                                    {editAdditionalCharges ? (
                                                                                        <Form.Item
                                                                                            name={`AdditionalCharges.${field.id}.name`}
                                                                                            label={
                                                                                                <strong>
                                                                                                    Additional Charge Name
                                                                                                </strong>
                                                                                            }
                                                                                            rules={[
                                                                                                {
                                                                                                    required: true,
                                                                                                    message: "Charge Name is required",
                                                                                                },
                                                                                            ]}
                                                                                            className="mb-0"
                                                                                            initialValue={field.name}
                                                                                        >
                                                                                            <Input
                                                                                                className="w-100"
                                                                                                disabled={!editAdditionalCharges}
                                                                                                autoComplete="off"
                                                                                                defaultValue={field.name}
                                                                                            />
                                                                                        </Form.Item>
                                                                                    ) : (
                                                                                        <strong>{field.name}</strong>
                                                                                    )}
                                                                                </div>
                                                                                <div className="w-30">
                                                                                    {editAdditionalCharges ? (
                                                                                        <Form.Item
                                                                                            name={`AdditionalCharges.${field.id}.price`}
                                                                                            label={<strong>Price (INR)</strong>}
                                                                                            rules={[
                                                                                                {
                                                                                                    required: true,
                                                                                                    message: "Price is required",
                                                                                                },
                                                                                            ]}
                                                                                            className="mb-0"
                                                                                            initialValue={Number(field.price)}
                                                                                        >
                                                                                            <InputNumber
                                                                                                min={0}
                                                                                                disabled={!editAdditionalCharges}
                                                                                                defaultValue={Number(field.price)}
                                                                                                className="w-100"
                                                                                                formatter={(value) =>
                                                                                                    `${value}`.replace(
                                                                                                        /\B(?=(\d{3})+(?!\d))/g,
                                                                                                        ","
                                                                                                    )
                                                                                                }
                                                                                                parser={(value: any) =>
                                                                                                    value!.replace(/\$\s?|(,*)/g, "")
                                                                                                }
                                                                                                onKeyPress={(event) => {
                                                                                                    if (!/^[0-9]+$/.test(event.key)) {
                                                                                                        event.preventDefault();
                                                                                                    }
                                                                                                }}
                                                                                                autoComplete="off"
                                                                                            />
                                                                                        </Form.Item>
                                                                                    ) : (
                                                                                        <strong>
                                                                                            {FormatCurrency(
                                                                                                field.price,
                                                                                                "INR"
                                                                                            )}
                                                                                        </strong>
                                                                                    )}
                                                                                </div>
                                                                                <Col className="d-flex align-items-end w-15">
                                                                                    {editAdditionalCharges && (
                                                                                        <a
                                                                                            href="#dropdownitem"
                                                                                            onClick={() => {
                                                                                                form2.setFieldsValue({
                                                                                                    [`AdditionalCharges.${field.id}.name`]:
                                                                                                        "",
                                                                                                    [`AdditionalCharges.${field.id}.price`]:
                                                                                                        null,
                                                                                                });
                                                                                                const newAdditionalCharges: any = [];
                                                                                                additionalCharges.forEach(
                                                                                                    (fld: any, ind: number) => {
                                                                                                        if (fld.id != field.id) {
                                                                                                            const name =
                                                                                                                form2.getFieldValue(
                                                                                                                    `AdditionalCharges.${fld.id}.name`
                                                                                                                );
                                                                                                            const price =
                                                                                                                form2.getFieldValue(
                                                                                                                    `AdditionalCharges.${fld.id}.price`
                                                                                                                );
                                                                                                            if (name && price) {
                                                                                                                newAdditionalCharges.push({
                                                                                                                    name,
                                                                                                                    price,
                                                                                                                    id: fld.id,
                                                                                                                });
                                                                                                            }
                                                                                                        }
                                                                                                    }
                                                                                                );
                                                                                                setAdditionalCharges(
                                                                                                    newAdditionalCharges
                                                                                                );
                                                                                                setShowSubmitBtm(true);
                                                                                            }}
                                                                                            className="addNewItem"
                                                                                        >
                                                                                            <DeleteOutlined />
                                                                                        </a>
                                                                                    )}
                                                                                </Col>
                                                                            </Row>
                                                                        );
                                                                    }
                                                                )}
                                                                {additionalCharges?.length > 0 &&
                                                                    editAdditionalCharges && (
                                                                        <Button
                                                                            className="m-4 mt-3 mb-0"
                                                                            color="primary"
                                                                            onClick={() => {
                                                                                const newAdditionalCharges: any = [];
                                                                                additionalCharges.forEach(
                                                                                    (field: any, index: number) => {
                                                                                        const name = form2.getFieldValue(
                                                                                            `AdditionalCharges.${field?.id}.name`
                                                                                        );
                                                                                        const price = form2.getFieldValue(
                                                                                            `AdditionalCharges.${field?.id}.price`
                                                                                        );
                                                                                        console.log(field?.id, name, price);

                                                                                        if (name && price) {
                                                                                            newAdditionalCharges.push({
                                                                                                name,
                                                                                                price,
                                                                                                id: field?.id,
                                                                                            });
                                                                                        }
                                                                                    }
                                                                                );
                                                                                setAdditionalCharges(() => {
                                                                                    return newAdditionalCharges;
                                                                                });
                                                                                if (newAdditionalCharges?.length != 0) {
                                                                                    setEditAdditionalCharges(false);
                                                                                }

                                                                                setShowSubmitBtm(true);
                                                                            }}
                                                                        >
                                                                            Save
                                                                        </Button>
                                                                    )}
                                                            </div>
                                                        </Row>
                                                    </Form>

                                                    <ViewTableTotals
                                                        data={data}
                                                        additionalCharges={additionalCharges}
                                                        invoice={true}
                                                    />
                                                </div>
                                                <Row className="m-4 justify-content-end ">
                                                    {signature?.isActive && (
                                                        <div className="signatureBtnWrapper">
                                                            <div className="d-flex justify-content-between align-items-center">
                                                                <strong>Signature</strong>
                                                            </div>
                                                            <div
                                                                style={{
                                                                    minHeight: signature?.image ? "0px" : "120px",
                                                                    display: signature?.image ? "block" : "flex",
                                                                    alignItems: "center",
                                                                    justifyContent: "center",
                                                                }}
                                                            >
                                                                {signature?.image && (
                                                                    <>
                                                                        <div className="signatueImgWrapper border">
                                                                            <img
                                                                                src={signature?.image}
                                                                                alt="Signed"
                                                                                style={{ width: "160px" }}
                                                                            />
                                                                        </div>

                                                                        <div>
                                                                            <div className="form-group">
                                                                                <label
                                                                                    className="form-label"
                                                                                    htmlFor="signatureLabel"
                                                                                >
                                                                                    Signature Label
                                                                                </label>
                                                                                <input
                                                                                    type="text"
                                                                                    className="form-control"
                                                                                    name="signatureLabel"
                                                                                    onChange={(e) => { }}
                                                                                    disabled
                                                                                    defaultValue={
                                                                                        signature?.label ||
                                                                                        "Authorised Signatory"
                                                                                    }
                                                                                />
                                                                            </div>
                                                                        </div>
                                                                    </>
                                                                )}
                                                            </div>
                                                        </div>
                                                    )}
                                                </Row>
                                            </div>
                                        </Panel>
                                        <Panel header="Other Information" key="3">
                                            <Descriptions
                                                title=""
                                                className="custom-description-title"
                                            >
                                                <Descriptions.Item label="">
                                                    <div>
                                                        <strong className="">
                                                            Required Documents &nbsp;
                                                        </strong>
                                                        {reqDocAttachments.map((docs: any) => (
                                                            <div key={docs.docId} className="w-auto m-3 mt-2 mb-0">
                                                                {/* {JSON.stringify(docs)} */}
                                                                <div>
                                                                    <strong>{docs.requiredDocsName} &nbsp; &nbsp;</strong>
                                                                    <Upload {...uploadProps} id={docs.docId}>
                                                                        <CloudUploadOutlined />
                                                                    </Upload>
                                                                    {docs && !newReqAttachment.some((file: any) => file.name === docs.name) && (
                                                                        <a
                                                                            href={docs?.link}
                                                                            target="_blank"
                                                                            rel="noopener noreferrer"
                                                                        >
                                                                            {docs?.name}
                                                                        </a>
                                                                    )}
                                                                </div>
                                                            </div>
                                                        ))}
                                                    </div>
                                                </Descriptions.Item>
                                                <Descriptions.Item label="Terms and Conditions">
                                                    <span
                                                        style={{
                                                            color: "#2263b3",
                                                            textDecoration: "underline",
                                                            cursor: "pointer",
                                                        }}
                                                        onClick={() => setTermsVisible(true)}
                                                    >
                                                        View
                                                    </span>
                                                </Descriptions.Item>

                                                <Descriptions.Item label="">
                                                    <div>
                                                        <strong className="">
                                                            Attachments &nbsp;
                                                            <PaperClipOutlined />
                                                        </strong>
                                                        <div>
                                                            {attachments?.map(
                                                                (attachment: any, index: number) => (
                                                                    <div
                                                                        key={index}
                                                                        className="w-100 d-flex justify-content-between"
                                                                    >
                                                                        <div>
                                                                            <strong>{index + 1}.</strong>
                                                                            &nbsp;
                                                                            {attachment.fileName}
                                                                            &nbsp; &nbsp; &nbsp; &nbsp;
                                                                        </div>
                                                                        <div>
                                                                            <a
                                                                                href={attachment.link}
                                                                                download={attachment.link}
                                                                                style={{
                                                                                    color: "black",
                                                                                    fontSize: "18px",
                                                                                }}
                                                                            >
                                                                                <DownloadOutlined />
                                                                            </a>
                                                                            &nbsp; &nbsp;
                                                                            <a
                                                                                style={{
                                                                                    color: "red",
                                                                                    fontSize: "16px",
                                                                                }}
                                                                                onClick={() => {
                                                                                    setDeletedAttachments(
                                                                                        (prev: any) => [
                                                                                            ...prev,
                                                                                            { docId: attachment?.docId },
                                                                                        ]
                                                                                    );
                                                                                    let updatedAttachmentsList =
                                                                                        attachments?.filter(
                                                                                            (item: any, i: number) =>
                                                                                                i != index
                                                                                        );
                                                                                    setAttachments(
                                                                                        updatedAttachmentsList
                                                                                    );
                                                                                    setShowSubmitBtm(true);
                                                                                }}
                                                                            >
                                                                                <DeleteOutlined />
                                                                            </a>

                                                                        </div>
                                                                    </div>
                                                                )
                                                            )}
                                                            {newAttachments?.map(
                                                                (attachment: any, index: number) => (
                                                                    <div
                                                                        key={index}
                                                                        className="w-100 d-flex justify-content-between"
                                                                    >
                                                                        <div>
                                                                            <strong>
                                                                                {attachments?.length + index + 1}.
                                                                            </strong>
                                                                            &nbsp;
                                                                            {attachment?.name}
                                                                            &nbsp; &nbsp; &nbsp; &nbsp;
                                                                        </div>
                                                                        <div>
                                                                            <a
                                                                                href={attachment.link}
                                                                                download={attachment.link}
                                                                                style={{
                                                                                    color: "black",
                                                                                    fontSize: "18px",
                                                                                }}
                                                                            >
                                                                                <DownloadOutlined />
                                                                            </a>
                                                                            &nbsp; &nbsp;
                                                                            <a
                                                                                style={{
                                                                                    color: "red",
                                                                                    fontSize: "16px",
                                                                                }}
                                                                                onClick={() => {
                                                                                    setNewAttachments((prev: any) =>
                                                                                        prev.filter(
                                                                                            (item: any, i: number) =>
                                                                                                i != index
                                                                                        )
                                                                                    );
                                                                                    setShowSubmitBtm(true);
                                                                                }}
                                                                            >
                                                                                <DeleteOutlined />
                                                                            </a>
                                                                        </div>
                                                                    </div>
                                                                )
                                                            )}
                                                            {(
                                                                <div className="mt-1">
                                                                    <Dragger
                                                                        beforeUpload={beforeUpload}
                                                                        fileList={[]}
                                                                        onChange={handleAttachmentChange}
                                                                    >
                                                                        <a
                                                                            onClick={() => { }}
                                                                            className="addNewItem"
                                                                        >
                                                                            <Icon name="plus-round" />
                                                                            <span>Add Attachment</span>
                                                                        </a>
                                                                    </Dragger>
                                                                </div>
                                                            )}
                                                        </div>
                                                    </div>
                                                </Descriptions.Item>

                                                {/* {suppliers &&
                                                    <Descriptions.Item label="Suppliers">
                                                        {suppliers.map((item) => (
                                                            <li>
                                                                {item.supplierName}
                                                            </li>
                                                        )
                                                        )}
                                                    </Descriptions.Item>
                                                } */}
                                            </Descriptions>
                                        </Panel>
                                    </AntCollapse>
                                </div>

                                {addNewRowModel && (
                                    <MaterialModal
                                        modal={addNewRowModel}
                                        closeModal={() => {
                                            setAddNewRowModel(false);
                                            setEditLineRow(null);
                                        }}
                                        status={rfqStatus}
                                        formData={{
                                            action: editLineRow == null ? "add" : "edit",
                                            index: editLineRow?.quotationLineId,
                                            item: editLineRow,
                                        }}
                                        append={(data: any) => {
                                            console.log("append");
                                            let newLineItems = materialData?.supplierInfo?.supplierLines;
                                            const _data = newLineItems.find((item: any) => {
                                                if (
                                                    item.materialId == data.materialId
                                                    // &&
                                                    // dayjs(item.needByDate).format("DD-MMM-YYYY") ==
                                                    // dayjs(data.needByDate).format("DD-MMM-YYYY") &&
                                                    // item.deliveryAddressId == data.deliveryAddressId
                                                )
                                                    return true;
                                            });

                                            if (_data) {
                                                Swal.fire({
                                                    icon: "error",
                                                    title: "Error",
                                                    text: "Material Already Added",
                                                });
                                                return;
                                            }

                                            setMaterialData((pre: any) => {
                                                let newData = { ...pre };
                                                newData?.quotationLines?.push(data);
                                                console.log("addNEW::DATA", newData)
                                                return newData;
                                            });
                                            setData((pre: any) => {
                                                return [...pre, data];
                                            });
                                            setShowSubmitBtm(true);
                                        }}
                                        update={(id: any, data: any) => {
                                            console.log("update", data, id);
                                            let newLineItems = materialData?.supplierInfo?.supplierLines;
                                            console.log("newLineItems", newLineItems)
                                            const _data = newLineItems
                                                .filter((item: any) => item.quotationLineId != id)
                                                .find((item: any) => {
                                                    if (
                                                        item.materialId == data.materialId
                                                        // &&
                                                        // dayjs(item.needByDate).format("DD-MMM-YYYY") ==
                                                        // dayjs(data.needByDate).format(
                                                        //     "DD-MMM-YYYY"
                                                        // ) &&
                                                        // item.deliveryAddressId == data.deliveryAddressId
                                                    )
                                                        return true;
                                                });
                                            console.log("_dataaa", _data)
                                            if (_data) {
                                                Swal.fire({
                                                    icon: "error",
                                                    title: "Error",
                                                    text: " Material Already Added",
                                                });
                                                return;
                                            }

                                            newLineItems = newLineItems.map((item: any) => {
                                                if (item.quotationLineId == id) {
                                                    return data;
                                                }
                                                return item;
                                            });
                                            console.log("newLineItems", newLineItems)
                                            setMaterialData((pre: any) => {
                                                let newData = { ...pre, quotationLines: newLineItems };
                                                console.log("newData", newData)
                                                return newData;
                                            });
                                            setData(newLineItems);
                                            setShowSubmitBtm(true);
                                        }}
                                    // needByDateType={materialData?.needByDateType}
                                    // deliveryAddressType={materialData?.deliveryAddressType}
                                    />
                                )}
                            </div>




                        )}
                    </div>
                </Card>
            </Content>
            <AntModal
                title={undefined}
                visible={instructionsVisible}
                footer={null}
                onCancel={() => {
                    setIsInstructionsChanged(false);
                    setInstructionsVisible(false);
                }}
                width="60%"
                bodyStyle={{ maxHeight: "70vh", overflowY: "auto" }}
            >
                <div className="d-flex">
                    <h5 className="m-3">Instructions </h5>
                    {!isInstructionsChanged &&
                        ["Pending", "Draft", "REQUESTED"].includes(rfqStatus) && (
                            <EditOutlined onClick={() => setIsInstructionsChanged(true)} />
                        )}
                </div>
                {["Pending", "Draft", "REQUESTED"].includes(rfqStatus) && isInstructionsChanged ? (
                    <ReactQuill
                        theme="snow"
                        value={newInstructions}
                        onChange={(data) => {
                            setNewInstructions(data);
                        }}
                        className="mb-3"
                    />
                ) : instructions ? (
                    <div
                        dangerouslySetInnerHTML={{
                            __html: instructions || "",
                        }}
                        className="m-3"
                    />
                ) : (
                    <div
                        style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            height: "200px",
                        }}
                    >
                        <Empty description="No Instructions Available for this Purchase Order" />
                    </div>
                )}
                {isInstructionsChanged && (
                    <ul className="actions clearfix d-flex">
                        <li>
                            <Button
                                color="primary"
                                type="submit"
                                onClick={() => {
                                    setInstructionsVisible(false);
                                    setIsInstructionsChanged(true);
                                    setShowSubmitBtm(true);
                                }}
                            >
                                Submit
                            </Button>
                        </li>
                        <li className="m-3 mt-0">
                            <Button
                                color="primary"
                                onClick={() => {
                                    setIsInstructionsChanged(false);
                                    setNewInstructions(instructions);
                                }}
                                className="btn-dim btn btn-secondary"
                            >
                                Cancel
                            </Button>
                        </li>
                    </ul>
                )}
            </AntModal>
            <AntModal
                title={undefined}
                visible={termsVisible}
                footer={null}
                onCancel={() => {
                    setIsTermsAndConditionsChanged(false);
                    setTermsVisible(false);
                }}
                width="60%"
                bodyStyle={{ maxHeight: "70vh", overflowY: "auto" }}
            >
                <div className="d-flex">
                    <h5 className="m-3">Terms and Conditions</h5>
                    {!isTermsAndConditionsChanged &&
                        <EditOutlined
                            onClick={() => setIsTermsAndConditionsChanged(true)}
                        />
                    }
                </div>
                {
                    isTermsAndConditionsChanged ? (
                        <ReactQuill
                            theme="snow"
                            value={newTermsAndConditions}
                            onChange={(data) => {
                                setNewTermsAndConditions(data);
                            }}
                            className="mb-3"
                        />
                    ) : termsAndConditions ? (
                        <div
                            dangerouslySetInnerHTML={{
                                __html: termsAndConditions || "",
                            }}
                            className="m-3"
                        />
                    ) : (
                        <div
                            style={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                height: "200px",
                            }}
                        >
                            <Empty description="No Terms and Conditions Available for this Purchase Order" />
                        </div>
                    )}

                {isTermsAndConditionsChanged && (
                    <ul className="actions clearfix d-flex">
                        <li>
                            <Button
                                color="primary"
                                type="submit"
                                onClick={() => {
                                    setTermsVisible(false);
                                    setIsTermsAndConditionsChanged(true);
                                    setShowSubmitBtm(true);
                                }}
                            >
                                Submit
                            </Button>
                        </li>
                        <li className="m-3 mt-0">
                            <Button
                                color="primary"
                                onClick={() => {
                                    setIsTermsAndConditionsChanged(false);
                                    setNewTermsAndConditions(termsAndConditions);
                                }}
                                className="btn-dim btn btn-secondary"
                            >
                                Cancel
                            </Button>
                        </li>
                    </ul>
                )}
            </AntModal>
        </>)

};



export default EditQuotedView;
